<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافه جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/years')"
        />
      </template>
    </Toolbar>
    <div class="form-card">
      <div class="mb-3">
        <label for="leaguesId" class="form-label">
          المسابقه
        </label>
        <select
          class="form-select form-control"
          id="leaguesId"
          name="leaguesId"
          v-model="body.leaguesId"
        >
          <option v-for="item of leaguesList" :key="item.id" :value="item.id">{{
            item.name
          }}</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="name" class="form-label">
          الاسم
        </label>
        <input
          type="text"
          class="form-control"
          id="name"
          name="name"
          v-model="body.name"
        />
      </div>

      <div class="mb-3">
        <label for="startDate" class="form-label">
          تاريخ البداء
        </label>
        <input
          type="date"
          class="form-control"
          id="startDate"
          name="startDate"
          v-model="body.startDate"
        />
      </div>
      <div class="mb-3">
        <label for="endDate" class="form-label">
          تاريخ الانتهاء
        </label>
        <input
          type="date"
          class="form-control"
          id="endDate"
          name="endDate"
          v-model="body.endDate"
        />
      </div>

      <div class="mb-3">
        <label for="limitTeam" class="form-label">
          اقصي عدد فرق
        </label>
        <input
          type="number"
          class="form-control"
          id="limitTeam"
          v-model.number="body.limitTeam"
        />
      </div>

      <div class="mb-3">
        <label for="playerCount" class="form-label">
          عداد اللاعبين
        </label>
        <input
          type="number"
          class="form-control"
          id="playerCount"
          v-model.number="body.playerCount"
        />
      </div>

      <div class="mb-3">
        <label for="plan" class="form-label">
          مساحه الملعب عداد الاشواط ومده المباره
        </label>
        <input class="form-control" id="plan" v-model="body.plan" />
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil"
          class="p-ml-2 p-button-info"
          @click="update()"
          v-if="id"
        />
        <Button
          v-else
          label="اضافه جديد"
          icon="pi pi-plus"
          class="p-ml-2 p-button-success"
          @click="save()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        name: null,
        startDate: null,
        endDate: null,
        limitTeam: 0,
        leaguesId: null,
        playerCount: 22,
        plan: null,
      },
      id: null,
      leaguesList: [],
    };
  },
  methods: {
    save() {
      if (
        this.body.name &&
        this.body.startDate &&
        this.body.endDate &&
        this.body.limitTeam &&
        this.body.leaguesId
      ) {
        this.$http.post(`years`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافه بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/years');
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
    update() {
      if (
        this.body.name &&
        this.body.startDate &&
        this.body.endDate &&
        this.body.limitTeam &&
        this.body.leaguesId
      ) {
        this.$http.put(`years/${this.id}`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/years');
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
  },
  created() {
    this.$http.get(`league`).then((response) => {
      this.leaguesList = response.data;
      this.body.leaguesId = this.leaguesList[0]?.id;
    });
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`years/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.leaguesId = res.data.leaguesId?.id;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>
